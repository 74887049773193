@font-face {
	font-family: 'Aldrich';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: local('Aldrich'), url(./assets/fonts/Aldrich-Regular.woff2) format('woff2');
}

@font-face {
	font-family: 'Aldrich';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: local('Aldrich'), url(./assets/fonts/Aldrich-Regular.woff) format('woff');
}

@font-face {
	font-family: 'Aldrich';
	font-weight: normal;
	font-style: normal;
	font-display: swap;
	src: local('Aldrich'), url(./assets/fonts/Aldrich-Regular.ttf) format('truetype');
}

body {
	margin: 0;
	font-family: 'Aldrich' !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}


.bgColorBrand {
	background-color: #fff
}

.bgColor {
	background: #fff;
	background: #fff;
}

.bgColor.navbar {
	padding-top: 0;
	padding-bottom: 0;
	box-shadow: 1px -4px 8px #ccc;
	border-bottom: solid 3px #be2426;
}

.bgColor.navbar-light .navbar-nav .nav-link.active,
.bgColor.navbar-light .navbar-nav .nav-link:active,
.bgColor.navbar-light .navbar-nav .nav-link:focus,
.bgColor.navbar-light .navbar-nav .nav-link:hover,
.bgColor.navbar-light .navbar-nav .show>.nav-link {
	color: #fff;
	background: #fd1d1d !important;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #a31112 !important;
	text-decoration: none;
	background-color: #e9ecef !important
}

.bgColor.navbar-light .navbar-nav .nav-link {
	color: #000;
	padding: 1rem .5rem;


	text-align: left;
	line-height: 28px;
}

.navbar-light .navbar-nav .nav-link {
	color: #000;


}

.navbar-light .navbar-nav .nav-link:hover {
	color: #fff !important;
	background: #fd1d1d !important;
}


.navbar__link_d {
	text-align: center
}

.navbar__link,
.navbar__link_d {
	color: #000;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-decoration: none
}

.navbar__link__dropdown {
	color: rgba(0, 0, 0, 0.87);
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-decoration: none
}

.navbar__link {
	padding: .9rem .5rem;
	border-right: 1px solid #b35d5d;

	text-align: left;
	color: #000;
	display: flex;
	vertical-align: middle;
	line-height: 28px;
}

.navbar__link:hover {
	color: #fff !important;
	background: #fd1d1d;
}

.navbar__link svg:hover {
	color: #fff !important;
	fill: #fff;
}

.navbar__link--active {
	color: inherit;
	background-color: #fd1d1d
}

.navbar__link--active,
.navbar__link--active_d {
	background-color: #fd1d1d
}

.tooltippop {
	position: relative;
	display: inline-block
}

.tooltip-bottom {
	top: 135%;
	left: -16px
}

.tooltip-bottom:after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 20px;
	margin-left: -5px;
	border: 10px solid transparent;
	border-bottom-color: #e3e3e3
}

.chargeidin {
	font-size: 15px;
	color: #000;
	width: 100%;
	padding: 5px 0
}

.tooltiptext {

	width: 200px;
	background-color: #fff;
	padding: 10px;
	text-align: left;
	border-radius: 6px;
	box-shadow: 0 0 5px #000;


}


.greendot {
	background: #4d8412
}

.greendot,
.yellowdot {
	width: 12px;
	height: 12px;
	margin-right: 2px;
	display: inline-block;
	margin-bottom: -2px;
	border-radius: 12px
}

.yellowdot {
	background: #ffd300
}

.reddot {
	background: #e02020
}

.grydot,
.reddot {
	width: 12px;
	height: 12px;
	margin-right: 2px;
	display: inline-block;
	margin-bottom: -2px;
	border-radius: 12px
}

.grydot {
	background: #bababa
}

.orangedot {
	background: #f60
}

.oragne2dot,
.orangedot {
	width: 12px;
	height: 12px;
	margin-right: 2px;
	display: inline-block;
	margin-bottom: -2px;
	border-radius: 12px
}

.oragne2dot {
	background: #ca5101
}

.browndot {
	width: 12px;
	height: 12px;
	margin-right: 2px;
	display: inline-block;
	margin-bottom: -2px;
	border-radius: 12px;
	background: #e9a374
}
.chargingstationfootericon svg{
	color: #a31112;
}
.bluedot {
	background: #0091ff
}

.blackdot,
.bluedot {
	width: 12px;
	height: 12px;
	margin-right: 2px;
	display: inline-block;
	margin-bottom: -2px;
	border-radius: 12px
}

.blackdot {
	background: #000
}

.green2dot {
	width: 12px;
	height: 12px;
	margin-right: 2px;
	background: #bde279;
	display: inline-block;
	margin-bottom: -2px;
	border-radius: 12px
}

.indicatordot {
	margin-right: 10px;
	color: #000;
	font-size: 13px
}

.bluecolor {
	color: #0091ff !important
}

.greencolor {
	color: #4d8412 !important
}

.boxhead {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	color: #222121
}

.totalkwh {
	font-size: 16px;
	Color: #222121
}

.totalkwh span {
	font-size: 16px;
	Color: #a31135;
	font-weight: 600
}

.tablelocation {
	width: 100%
}

.tablelocation thead tr th {
	font-size: 13px;
	color: #000;
	border-bottom: 1px solid #ccc;
	padding: 5px
}

.tablelocation td {
	font-size: 13px;
	color: #000;
	border-bottom: 1px dashed #ccc;
	padding: 5px
}

.selectfilter {
	border: 1px solid #ccc;
	font-size: 12px
}

.totalgal {
	font-size: 16px;
	Color: #000
}

.countnumber {
	font-weight: 600;
	font-size: 25px
}

.boxbottomup {
	font-size: 16px;
	Color: #87a2ba;
	margin-top: 15px
}

.boxbottomup span {
	font-size: 20px;
	Color: #2dce89
}

.boxbottomdown {
	font-size: 16px;
	Color: #87a2ba;
	margin-top: 15px
}

.boxbottomdown span {
	font-size: 20px;
	Color: #e7090b
}

.textright {
	text-align: right
}

.textcenter1 {
	text-align: center;
	margin: 20px auto
}

.acreading {
	background: #ff4b4c
}

.acreading,
.dcreading {
	border-radius: 10px;
	display: block;
	height: 100px;
	width: auto;
	text-align: center;
	line-height: 100px;
	color: #fff;
	font-size: 24px;
	margin: auto;
}

.dcreading {
	background: #0086be
}

.borderred,
.card {

	border-radius: 10px
}

.textcenter2 {
	text-align: center
}

.sessionshead {
	background: rgb(161 16 17) !important;
	padding: 10px !important;
	color: #fff;
	border-radius: 6px 6px 0 0
}

.card-title2 {
	color: #fff
}

.totalkwhleft {
	color: #dbd0d0;
	margin-top: 10px;
	text-align: left
}

.totalkwhleft span {
	color: #fff
}

.totalkwhright {
	color: #dbd0d0;
	margin-top: 10px;
	text-align: right
}

.totalkwhright span {
	color: #fff
}

.responsiveCharts {
	box-sizing: border-box;
	padding: 10px;
	width: 100%;
	height: 270px;
	background-color: #fff
}

.responsiveChartsChargerGroup {
	box-sizing: border-box;
	padding: 10px;
	width: 100%;
	height: 800px;
	background-color: #fff
}

@media (max-width:1200px) {
	.css-vubbuv {
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		width: 1em;
		height: 21px;
		display: inline-block;
		fill: currentcolor;
		flex-shrink: 0;
		transition: fill .2s cubic-bezier(.4, 0, .2, 1) 0ms;
		font-size: 1.5rem;
		margin-top: -3px
	}

	.navbar__link {
		display: flex;
		vertical-align: middle
	}

	.bgColor.navbar-light .navbar-nav .nav-link,
	.navbar__link {
		padding: .9rem .5rem;

		min-width: auto;
		text-align: left;
		font-size: 12px
	}

	.bgColor.navbar-light .navbar-nav .nav-link {
		color: #000
	}

	.boxhead {
		font-size: 13px;
		text-transform: uppercase;
		color: #222121;
		margin: auto;
		text-align: left !important;

	}

	.boxhead,
	.countnumber {
		font-weight: 600;
		text-align: left;
	}

	.countnumber {
		font-size: 21px;
	}

	.boxicon {
		max-width: 59px;
		width: 100%;
		margin: auto;
		text-align: center;
		float: none;
		display: block;
		cursor: pointer;
	}
}






.loginleftbg {
	background: #f2f3f8;
	padding: 5% 2%;
	height: 100vh;

}

.loginleftlogo {
	width: 100%;
}

.loginheadtext {
	font-size: 30px;
	font-weight: 600;
	color: #000;
	padding-top: 10px;
	line-height: 35px;
}

.loginheadtext span {
	color: #bf2326;
}

.loginsmalltext {
	font-size: 15px;
	color: #000;
	max-width: 430px;

}

.loginsmalltext span {
	color: #bf2326;
}

.ydot {
	padding-top: 20px;
}

.loginwindow {
	padding-top: 15%;
}

.bottomtext2 {
	display: none;
}

.weblogo {
	display: block;
}

.logomobile {
	display: none;
}

.menuicon {

	max-width: 28px;
	float: left;

}

.menuicon:hover {
	fill: #fff;
}

@media (max-width:767px) {

	.weblogo {
		display: none;
	}

	.logomobile {
		display: block;
		max-height: 44px;
	}


	.bottomtext2 {
		display: block;
	}

	.loginsmalltext {
		font-size: 15px;
		color: #000;
		max-width: 100%;
		text-align: center;

	}

	.loginleftbg {
		display: none;
	}

	.loginsmalltext {
		font-size: 12px;
		color: #000;
		max-width: 100%;
		text-align: center;
	}

	.loginheadtext {
		font-size: 15px;
		font-weight: 600;
		color: #000;
		padding-top: 10px;
		line-height: 20px;
	}

	.loginheadtext span {
		color: #bf2326;
	}

}


.App {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 650px;

	padding-bottom: 25px;
}





.output-box {
	display: flex;
	flex-direction: column;
	align-items: center;

}


.grid-container {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

}

.text-background {
	padding: 4px 10px;
	background-color: #e0e0e0;
	border-radius: 20px;
}

.MuiInput-underline {
	border: none
}


.MuiTypography-displayInline {
	display: inline-flex !important;
}





.forSesionGraph .responsiveCharts .recharts-responsive-container .recharts-wrapper .recharts-legend-wrapper .recharts-default-legend .recharts-legend-item {
	margin-top: 5% !important;
}

.forgothead {
	text-align: left;
}

.forgothead h3 {
	font-size: 20px;
	padding: 30px 8px 20px 8px;

}

.logintext5 h4 {
	font-size: 13px;
	text-align: left;
	padding: 8px;
	display: block;
	color: #807f7e;
}

.loginwindow2 {
	padding-top: 2%
}

.loginwindow {
	padding-top: 15%
}

.bottomtext2 {
	display: none
}

.weblogo {
	display: block
}

.logomobile {
	display: none
}

.menuicon {
	max-width: 28px;
	float: left
}

.menuicon:hover {
	fill: #fff
}

.MuiSwitch-root {
	margin-left: -13px;
}

.forgothead {
	text-align: left;
}

.forgothead h3 {
	font-size: 20px;
	padding: 30px 0;
}

.d-blocki {
	display: block !important;
}

.w1001 {
	width: 100%;
}

.forgotinput {
	margin-bottom: 5px;
}

.ipuntinst h4 {
	font-size: 13px;
	text-align: left;
	padding: 8px 0 0;
	display: block;
	color: #807f7e;
}

.redbtn {
	width: 100%;
	padding: 8px;
	background: #a31112;
	color: #fff;
	border: 0;
	margin-top: 20px;
}

.loginwindow2 {
	padding-top: 2%;
	max-width: 444px;
}

.updatemessage {
	font-size: 30px;

}

.updatemessage h4 {
	font-size: 15px;
	color: #666;

}

.jss4 {
	max-width: 100px;
}

.textcolour a {
	color: #a31112;
	text-decoration: none;
	cursor: pointer;
}

.textcolour {
	color: #a31112;
	text-decoration: none;
	cursor: pointer;
}

.textcolour a:hover {
	color: #a31112;
	text-decoration: none;
}

.textcolourdelete {
	color: #a31112 !important;
	text-decoration: none !important;
	cursor: pointer !important;
	padding: 0 10px;

}

.textcolourdelete:hover {
	color: #a31112 !important;
	text-decoration: none !important;
	/* padding: 0 10px; */
	/* border: 1px solid #a31112 !important; */
}


.accordion-button {
	background-color: #c4c4c4 !important;
	color: #333 !important;
	font-size: 15px !important;
	text-transform: uppercase !important;
}

.accordion-button:not(.collapsed) {
	background-color: #d3d3d3 !important;
	color: #333 !important;
	text-transform: uppercase !important;
}

.time-slot {
	background-color: #e9e9e9 !important;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
}

.btn-icon {
	color: #8b0000 !important;
	background: none !important;
	border: none !important;
	padding: 0;
	font-size: 1.2rem;
}

.add-form input {
	border: none;
	border-bottom: 1px solid #ccc !important;
	border-radius: 0;
}

.btn-add {
	background-color: #8b0000 !important;
	color: white !important;
}

.slottime {
	min-width: 104px;
	margin-right: 20px;
	line-height: 30px;
}

.Variablefee {
	min-width: 20%;
	line-height: 30px;
}

.editicon {
	margin-right: 10px;
}

.actionicon {
	min-width: 99px;
}

.addslottext {
	padding-top: 10px;
	color: #8b0000 !important;
}

.accordion-button:focus {
	box-shadow: none !important;
}